import React, { useEffect, useRef, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./header.scss"
import bigFacebook from "../../assets/img/facebook-square-brands-1.svg"
import bigFacebookDark from "../../assets/img/facebook-square-brands-2.svg"
import bigLinked from "../../assets/img/linkedin-brands-1.svg"
import bigLinkedDark from "../../assets/img/linkedin-brands-2.svg"
import textData from "../../content/home.yaml"
import logo from "../../assets/img/Logo.svg"
import hamburger from "../../assets/img/Hamburger_icon.svg"
import hamburgerWhite from "../../assets/img/Hamburger_icon_white.svg"


const Header = ({scrollProgress}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => setPopupVisible(!isPopupVisible);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY && ref.current.getBoundingClientRect().top <= 0);
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', () => handleScroll);
  }, []);

  return <div className={`HeaderWrapper ${isSticky ? "Sticky" : ""}`} ref={ref}>
    <div className="HeaderContainer">
      <AnchorLink to="/#Home" className="Logo">
        <img src={logo} alt="Logo"/>
      </AnchorLink>

      <div className="RightSide">
        <div className="Links">
          <AnchorLink className={`Link ${scrollProgress === 1 ? 'Active' : ''}`} to="/#Pricing">{textData.header.pricing}</AnchorLink>
          <AnchorLink className={`Link ${scrollProgress === 2 ? 'Active' : ''}`} to="/#Contact">{textData.header.contact}</AnchorLink>
          <AnchorLink className={`Link ${scrollProgress === 0 ? 'Active' : ''}`} to="/#About">{textData.header.about}</AnchorLink>
        </div>
        <img className={`MenuButton ${isPopupVisible ? 'Active' : ''}`} src={!isPopupVisible && !isSticky ? hamburgerWhite : hamburger } onClick={togglePopup} alt={"Menu"}/>
        <div className="SocialWrapper">
        <a href="https://www.linkedin.com/in/patrick-nicholson-05227b1b5/?originalSubdomain=uk">
          <img className="Social" src={bigLinked} alt="linked"/>
        </a>
        <a href="https://www.facebook.com/cbos.uk/">
          <img className="Social" src={bigFacebook} alt="facebook"/>
        </a>
        </div>
      </div>
    </div>
    {isPopupVisible && <div className="PopupMenu" onClick={togglePopup}>
      <AnchorLink className="Link" to="/#Pricing">{textData.header.pricing}</AnchorLink>
      <AnchorLink className="Link" to="/#Contact">{textData.header.contact}</AnchorLink>
      <AnchorLink className="Link" to="/#About">{textData.header.about}</AnchorLink>
      <div className="SocialWrapper">
        <div className="SocialLink">
          <a href="https://www.linkedin.com/in/patrick-nicholson-05227b1b5/?originalSubdomain=uk">
            <img className="Social" src={bigLinkedDark} alt="linked"/>
          </a>
        </div>
        <div className="SocialLink">
          <a href="https://www.facebook.com/cbos.uk/">
            <img className="Social" src={bigFacebookDark} alt="facebook"/>
          </a>
        </div>
      </div>
    </div>}
  </div>
}

export default Header
