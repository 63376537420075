import React, { useState } from "react"
import "./contact.scss"
import textData from "../../content/home.yaml"
import * as yup from "yup"
import { toast } from "react-toastify"
import axios from "axios"

const phoneRegExp = /^\+?([0-9 ]*|)$/

const schema = yup.object().shape({
  name: yup.string("Field required").required("Field required"),
  mobile: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Field required"),
  email: yup.string("Field required").email("Invalid email").required("Field required"),
  message: yup.string("Field required").required("Field required"),
  enquiry: yup.number("Please select an option").required("Please select an option").typeError("Please select an option"),
  hear: yup.number("Please select an option").required("Please select an option").typeError("Please select an option")
})

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    enquiry: -1,
    hear: -1
  })

  const [formErrors, setFormErrors] = useState({})
  const [formBlur, setFormBlur] = useState({})

  const validate = async () => {
    const isValid = await schema.isValid(formState)

    if (isValid) {
      setFormErrors({})
      return true
    }

    try {
      await schema.validate(formState, { abortEarly: false })
    } catch (e) {
      const errors = {}
      e.inner.forEach(error => {
        errors[error.path] = error.message
      })
      setFormErrors(errors)
    }

    return false
  }

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    })

    validate()
  }

  const onBlur = (e) => {
    setFormBlur({
      ...formBlur,
      [e.target.name]: true
    })

    validate()
  }

  const setAllBlurred = () => {
    setFormBlur({
      name: true,
      mobile: true,
      email: true,
      message: true,
      enquiry: true,
      hear: true
    })
  }

  const [isSending, setIsSending] = useState(false)

  const onSubmit = async () => {
    setIsSending(true)
    setAllBlurred()
    
    try {
      if (await validate()) {
        const result = await axios.post("https://bnyt9wf30k.execute-api.eu-central-1.amazonaws.com/default/contact", formState, { headers: { "x-api-key": "uvysTAaXkh2oIXx9FWq5L2QPX5Ywmp2ua00hj2iI" } })

        if (result.data.success) {
          toast("Thanks for contacting us.", {
            type: "success"
          })
          setFormState({email: "", mobile: "", name: "", message: "", enquiry: -1, hear: -1});
        }
      }
    } catch (e) {
      toast(e.toString(), {
        type: "error"
      })
    } finally {
      setIsSending(false)
    }
  }

  return <div className="ContactContainer">
    <div className="Row">
      <div className="SubTitle" dangerouslySetInnerHTML={{ __html: textData.contact.selectType }}>
      </div>
    </div>
    <div className="Row Top-32">
      <div className="InputGroup">
        <div className="RadioGroup">
          {textData.contact.typeOptions.map((type, i) =>
            <label key={i} htmlFor={type.data.value}
                   className="control control-radio">
              {type.data.name}
              <input id={type.data.value} type="radio" name="enquiry" value={i} onBlur={onBlur}
                onChange={onChange} checked={(formState["enquiry"] == i)}/>
              <div className="control_indicator"/>
            </label>
          )}
        </div>
        {formErrors["enquiry"] && formBlur["enquiry"] && <div className="Error">{formErrors["enquiry"]}</div>}
      </div>
    </div>
    <div className="Row">
      <div className="InputGroup">
        <input className={`Input SpaceRight ${formErrors["name"] && formBlur["name"] ? "Error" : ""}`} type="text"
               name="name" placeholder="Name" onBlur={onBlur} onChange={onChange} value={formState["name"]}/>
        {formErrors["name"] && formBlur["name"] && <div className="Error">{formErrors["name"]}</div>}
      </div>
      <div className="InputGroup">
        <input className="Input" type="text" name="mobile" placeholder="Mobile Phone" onBlur={onBlur}
               onChange={onChange} value={formState["mobile"]}/>
        {formErrors["mobile"] && formBlur["mobile"] && <div className="Error">{formErrors["mobile"]}</div>}
      </div>
    </div>
    <div className="Row">
      <div className="InputGroup">
        <input className="Input" type="text" name="email" placeholder="Email" onBlur={onBlur} onChange={onChange} value={formState["email"]}/>
        {formErrors["email"] && formBlur["email"] && <div className="Error">{formErrors["email"]}</div>}
      </div>
    </div>
    <div className="Row">
      <div className="SubTitle">
        {textData.contact.enquiryTitle}
      </div>
    </div>
    <div className="Row Top-16">
      <div className="InputGroup">
        <textarea className="Input" name="message" onBlur={onBlur} onChange={onChange} value={formState["message"]}/>
        {formErrors["message"] && formBlur["message"] && <div className="Error">{formErrors["message"]}</div>}
      </div>
    </div>
    <div className="Row">
      <div className="SubTitle">
        {textData.contact.selectWhereHeard}
      </div>
    </div>
    <div className="Row Top-32">
      <div className="InputGroup">
        <div className="Row">
          <div className="RadioGroup">
            <label htmlFor="hear1" className="control control-radio">
              {textData.contact.heardOptions[0].name}
              <input id="hear1" type="radio" name="hear" value="0" onBlur={onBlur} onChange={onChange} checked={(formState["hear"] == 0)}/>
              <div className="control_indicator"/>
            </label>
            <label htmlFor="hear2" className="control control-radio">
              {textData.contact.heardOptions[1].name}
              <input id="hear2" type="radio" name="hear" value="2" onBlur={onBlur} onChange={onChange} checked={(formState["hear"] == 2)}/>
              <div className="control_indicator"/>
            </label>
          </div>

          <div className="RadioGroup">
            <label htmlFor="hear3" className="control control-radio">
              {textData.contact.heardOptions[2].name}
              <input id="hear3" type="radio" name="hear" value="1" onBlur={onBlur} onChange={onChange} checked={(formState["hear"] == 1)}/>
              <div className="control_indicator"/>
            </label>
            <label htmlFor="hear4" className="control control-radio">
              {textData.contact.heardOptions[3].name}
              <input id="hear4" type="radio" name="hear" value="3" onBlur={onBlur} onChange={onChange} checked={(formState["hear"] == 3)}/>
              <div className="control_indicator"/>
            </label>
          </div>
        </div>
        {formErrors["hear"] && formBlur["hear"] && <div className="Error">{formErrors["hear"]}</div>}
      </div>
    </div>

    <div className="Row ButtonsWrapper Top-48">
      <button className="Button" onClick={onSubmit} disabled={isSending}>
        {isSending ? textData.contact.buttonSending : textData.contact.button}
      </button>
    </div>
  </div>
}

export default Contact
