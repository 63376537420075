import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import './footer.scss'
import textData from "../../content/home.yaml"
import logo from "../../assets/img/Logo_white.svg"
import facebook from "../../assets/img/facebook-square-brands-2.svg"
import linked from "../../assets/img/linkedin-brands-2.svg"
import privacyPolicePdf from "../../assets/pdf/Privacy Policy.pdf"

const Footer = () => {
  return <>
    <div className="FooterContainer">
      <AnchorLink className="Logo" to="/#Home">
        <img src={logo} alt="Logo"/>
      </AnchorLink>
      <div className="Links">
        <AnchorLink className="Link" to="/#Pricing">
          {textData.header.pricing}
        </AnchorLink>
        <AnchorLink className="Link" to="/#Contact">
          {textData.header.contact}
        </AnchorLink>
        <AnchorLink className="Link" to="/#About">
          {textData.header.about}
        </AnchorLink>
        <a className="Link" href={privacyPolicePdf}>{textData.header.privacyPolice}</a>
      </div>

      <div className="Social">
        <a href="https://www.linkedin.com/in/patrick-nicholson-05227b1b5/?originalSubdomain=uk">
          <img src={linked} alt="linked"/>
        </a>
        <a href="https://www.facebook.com/cbos.uk/">
          <img src={facebook} alt="facebook"/>
        </a>
      </div>
    </div>
  </>
};

export default Footer
