import React, { useEffect, useRef, useState } from "react"
import "./home.scss"
import Header from "../../components/header/header"
import Footer from "../../components/footer/footer"
import Contact from "../../components/contact/contact"
import MainPicture from "../../assets/img/main-picture@2x.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import textData from "../../content/home.yaml"
import ellipse from "../../assets/img/ellipse.svg"
import bubbles from "../../assets/img/background.svg"
import money from "../../assets/img/money.svg"
import calculator from "../../assets/img/calculator.svg"
import umbrella from "../../assets/img/umbrella.svg"
import check from "../../assets/img/check.svg"
import earth from "../../assets/img/earth.svg"
import education from "../../assets/img/education.svg"
import presentation from "../../assets/img/presentation.svg"
import wallet from "../../assets/img/wallet.svg"
import financial from "../../assets/img/financial.svg"
import business from "../../assets/img/business.svg"
import gear from "../../assets/img/gear.svg"
import marker from "../../assets/img/marker.svg"
import star from "../../assets/img/star.svg"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import AOS from "aos"
import "aos/dist/aos.css"

const Home = () => {
  const featureImgs = [money, calculator, umbrella];
  const moreThanImgs = [check, education, earth, presentation];
  const benefitImgs = [wallet, financial, business, gear];

  const aboutRef = useRef(null), contactRef = useRef(null), pricingRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(-1);

  useEffect(() => {
    AOS.init()

    const refs = [aboutRef, pricingRef, contactRef];
    const handleScroll = () => {
      let set = false;
      refs.forEach(((ref, i) => {
        if (window.scrollY && ref.current.getBoundingClientRect().top <= 150 && ref.current.getBoundingClientRect().top >= -500) {
          setScrollProgress(i);
          set = true;
        }
      }));
      !set && setScrollProgress(-1);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', () => handleScroll);
  }, []);

  const animation = {
    "data-aos": "fade-up",
    "data-aos-duration": "1000",
  };

  return <>
    <ToastContainer/>
    <Header scrollProgress={scrollProgress}/>
    <div className="MainWrapper" id="Home">
      <img className="Ellipse" src={ellipse} alt="ellipse"/>
      <div className="MainContainer">
        <div className="Header">
          <div className="Title">{textData.main.title}</div>
          <div className="Description" dangerouslySetInnerHTML={{ __html: textData.main.description }}>
          </div>
          <div className="Call">{textData.main.call}</div>
        </div>
        <img src={MainPicture} className="Photo" alt="Main"/>
      </div>
    </div>
    <div className="FeaturesContainer">
      <div className="Header" {...animation}>
        <div className="Title">
          {textData.features.title}
        </div>
        <div className="Description">
          {textData.features.description}
        </div>
      </div>
      <div className="Features">
        {textData.features.list.map((feature, i) => <div key={i} className="Feature" {...animation} data-aos-duration={`${(i*2)+10}00`}>
          <img className="Card" src={featureImgs[i]} alt={"feature1"}/>
          <div className="Title">
            {feature.data.title}
          </div>
          <div className="Description">
            {feature.data.description}
          </div>
        </div>)}
      </div>
    </div>

    <div className="FindOutContainer" id="About" ref={aboutRef}>
      <div className="Picture">

      </div>
      <div className="Content">
        <div className="Title">
          {textData.about.title}
        </div>
        <div className="Description" dangerouslySetInnerHTML={{__html: textData.about.description}}>
        </div>
        <ul className="List">
          {textData.about.list.map((item, i) => <li key={i}>{item.text}</li>)}
        </ul>
      </div>
    </div>

    <div className="MoreThanWrapper">
      <div className="MoreThanContainer">
        <div className="Header" {...animation}>
          <div className="Title">
            {textData.moreThan.title}
          </div>
          <div className="Description">
            {textData.moreThan.description}
          </div>
        </div>

        <div className="Cards">
          {textData.moreThan.cards.map((card, i) => <div key={i} className="Card" {...animation} data-aos-duration={`${(i*2)+10}00`}>
            <img className="Icon" src={moreThanImgs[i]} alt={"icon"}/>
            <div className="Title" dangerouslySetInnerHTML={{__html: card.data.title}}>
            </div>
            <div className="Description">
              {card.data.description}
            </div>
          </div>)}
        </div>
      </div>
    </div>

    <div className="BenefitsContainer">
      <div className="Header" {...animation}>
        <div className="Title">
          Benefits for All!
        </div>
        <div className="Description">
          Change is hard. We get it, but by utilising our services as either an Agency, Contractor
          or Worker we will be able to show you added value in a number of ways
        </div>
      </div>

      <div className="Benefits">
        {textData.benefits.list.map((benefit, i) => <div key={i} className="Benefit" {...animation} data-aos-duration={`${(i*2)+10}00`}>
          <div className="Card">
            <img className="Image" src={benefitImgs[i]} alt="Benefit"/>
          </div>
          <div className="Title">
            {benefit.data.title}
          </div>
          <div className="Description">
            {benefit.data.description}
          </div>
        </div>)}
      </div>
    </div>
    <div className="CustomerContainer">
      <div className="Header">
        <div className="Title">
          {textData.customers.title}
        </div>
        <div className="Description">
          {textData.customers.description}
        </div>
      </div>
      <div className="Customers">
        {textData.customers.list.map((customer, i) => <div key={i} className="Customer">
          <img className="Photo" src={customer.data.src} alt={customer.data.name}/>
          <div className="Info">
            <div className="Name">
              {customer.data.name}
            </div>
            <div className="Description">
              {customer.data.quote}
            </div>
            <div className="Stars">
              <img className="Star" src={star} alt="star"/>
              <img className="Star" src={star} alt="star"/>
              <img className="Star" src={star} alt="star"/>
              <img className="Star" src={star} alt="star"/>
              <img className="Star" src={star} alt="star"/>
            </div>
          </div>
        </div>)}
      </div>
    </div>
    <div className="PricingContainer" id="Pricing" ref={pricingRef}>
      <div className="Header" {...animation}>
        <div className="Title">
          {textData.pricing.title}
        </div>
        <div className="Description">
          {textData.pricing.description}
        </div>
      </div>

      <div className="Cards">
        {textData.pricing.prices.map((price, i) => <div key={i} className={`Card ${i === 1 ? 'Highlighted' : ''}`} {...animation} data-aos-duration={`${(i*2)+10}00`}>
          <div className="Title" dangerouslySetInnerHTML={{__html: price.data.title}}>
          </div>

          <img className="Marker" src={marker} alt=""/>

          <div className="RangeContainer">
            <div className="From">{price.data.from}</div>
            <div className="Currency">{price.data.currency}</div>
            <div className="Price">{price.data.price}</div>
            <div className="Period">{price.data.period}</div>
          </div>

          <ul className="List">
            {price.data.list.map((data, j) => <li key={j}>{data.text}</li>)}
          </ul>

          <AnchorLink className="Action" to="/#Contact">
            <div className="BigText">
              {price.data.button}
            </div>
            <div className="SmallText">
              {price.data.buttonSmall}
            </div>
          </AnchorLink>
        </div>)}
      </div>
    </div>
    <div className="ContactWrapper" id="Contact" ref={contactRef} {...animation}>
      <h1>{textData.contact.title}</h1>
      <Contact/>
      <img className="Ellipse" src={ellipse} alt="Ellipse"/>
      <img className="Bubbles" src={bubbles} alt="Bubbles"/>
    </div>
    <Footer/>
  </>
}

export default Home
